import React from "react"
import Layout from "../components/layout"
import DanStyledBackgroundSection from "../components/dan-background-image"
import Seo from "../components/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"

const DanRather = ({data}) => (
  <Layout>
    <Seo title="Dan Rather" />
    <div style={{
      backgroundColor: `#005f86`,
    }}>
      <DanStyledBackgroundSection />
      <div class="hero-textbox pad">
        <div id="award-hero-textbox">
        <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-10 offset-md-1 mb-5" style={{ color: `white`, textAlign: `left`}}>
            <h1 style={{
              color: `white`,
              textAlign: `center`,
            }} className="semibold serif mb-5">Dan Rather</h1>
            <h2 className="h3 mb-4 serif">A Lifelong Journalist</h2>
            <p className="copy">Dan Rather is and has long been a journalist. He started working as a journalist in his native Texas in 1950 and was the "CBS Evening News" anchor for 24 years from 1981 to 2005. He has covered every presidential election since 1964. As an investigative journalist for news magazines — from the iconic “60 Minutes” to the 21st century addition “Dan Rather Reports” — he has been a part of significant shifts in the journalism landscape.
</p>
                <p className="copy">Rather believes “A free and independent - fiercely independent when necessary - press is the red beating heart of American democracy.”
</p>
            <p className="copy">Born in Wharton, Texas, in 1931, Rather was interested in journalism from a young age.
</p>
            <p className="copy">“The dream begins with a teacher who believes in you,” he said. “Who tugs and pushes and leads you to the next plateau, sometimes poking you with a sharp stick called ‘truth.’"</p>

            {/* <h2 className="h3 mb-4 serif">The Start of a Long Career</h2> */}
            <p className="copy">After graduating from high school in Houston, he became a staff writer and editor for Sam Houston State College’s university paper, The Houstonian. He took his bachelor’s degree in journalism and the skills he gathered announcing play-by-plays at local athletic events, to work at the Houston Chronicle and KTRH radio.
</p>
            <p className="copy">He became a national name after his reporting on Hurricane Carla in 1961. Then a news director for CBS affiliate KHOU Channel 11 in Houston, Rather, 29, brought his team to the National Weather Center in Galveston. The Category 5 hurricane was of devastating scale, 150 mph winds across a 400-mile radius. At the weather center, Rather’s team was greeted with technology not commonly accessible by the public. The KHOU program manager Calvin Jones suggested they show the radar itself on TV, which led to Rather creating the first televised weather broadcast map by placing a transparent overlay on the device. Rather and his team’s coverage and on the ground, reporting helped facilitate hundreds of thousands of people’s evacuation.
</p>
            <p className="copy">The Carla coverage led to Rather to CBS, where he would do the journalistic work that brought him close to the major events of the 20th century.</p>

            <div className="mt-5 mb-3" style={{ textAlign: `center`, }}>
              <iframe style={{ maxWidth: `100%` }} title="Dan Rather: My First Big Break" width="560" height="315" src="https://www.youtube.com/embed/vmJnbeRr0vc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <h2 className="h3 mb-4 serif">A Front-Row Seat on History</h2>
            <p className="copy">He covered the Civil Rights Movement and was the first to report the death of Civil Rights leader Medgar Evers. Rather was one of the few journalists to see the Zapruder film of the Kennedy assassination, which he later described on television. He spent years on the ground covering Dr. Martin Luther King across the South.</p>
            <p className="copy">As an interviewer, Rather was known for his firm and, at times, confrontational style. He’s covered every U.S. president from Eisenhower to Trump, and personally interviewed most of them. He was on air for over 58 of the 93 uninterrupted hours of CBS’s coverage after the 9/11 terrorist attacks. Over the decades, he reported on the mujahideen, the end of apartheid, and the funeral of Japanese Emperor Hirohito. He was on the ground for the Vietnam War, the Gulf War, Tiananmen Square, the War in Afghanistan, and the Iraq War.</p>
            <p className="copy">Rather left CBS News after 2006, two years after the network retracted a "60 Minutes II" piece he produced that questioned President George W. Bush's National Guard service. Fourteen years later, he is popular on social media and still reporting with his production company and website News and Guts.</p>
            <p className="copy">“Can you still make a difference as a journalist?” Rather said at the Moody College of Communication in 2009. “Yes, if you don't quit."</p>

            <h2 className="h3 mb-4 serif">Books by Dan Rather</h2>
            <p className="copy">His books reflecting on life, America, and democracy.</p>

            <p className="copy">“The Palace Guard,” and Gary Paul Gates (1974)</p>
            <p className="copy">“The Camera Never Blinks: Adventures of a TV Journalist,” with Mickey Herskowitz (1977)</p>
            <p className="copy">“I Remember,” with Peter Wyden (1991)</p>
            <p className="copy">“Camera Never Blinks Twice: The Further Adventures of a Television Journalist,” with Mickey Herskowitz (1995</p>
            <p className="copy">“Our Times: America at the Birth of the Twentieth Century” abridged edition of Mark Sullivan’s six-volume series (1995)</p>
            <p className="copy">“Deadlines and Datelines: Essays at the Turn of the Century” (1999)</p>
            <p className="copy">“The American Dream: Stories From the Heart of Our Nation” (2001)</p>
            <p className="copy">“America at War: The Battle for Iraq: a View from the Frontlines,” and the reporters of CBS News (2003)</p>
            <p className="copy">“Rather Outspoken: My Life in the News,” with Digby Diehl (2012)</p>
            <p className="copy">“What Unites Us: Reflections on Patriotism,” with Elliot Kirschner (2017)</p>
          </div>
        </div>
      </div>
        </div>
      </div>


      <div className="dan-grid">
        <div className="image-wrapper">
          <Img
            fluid={data.dan1.childImageSharp.fluid}
            alt="Dan in the 1965s"
            style={{ width: `100%`, }}
          />
          <div className="textbox">
            <p>1952</p>
            <p>Rather, center, and his colleagues at The Houstonian, the campus newspaper at Sam Houston State University, where he served as editor.</p>
            <p style={{ fontSize: `1rem`, }}><em>Photo ℅ Briscoe Center for American History</em></p>
          </div>
        </div>
        <div className="image-wrapper">
          <Img
            fluid={data.dan2.childImageSharp.fluid}
            alt="Dan in the 1960s"
            style={{ width: `100%`, }}
          />
          <div className="textbox">
            <p>1964</p>
            <p>Rather as the CBS News Southern Bureau chief.</p>
          </div>
        </div>
        <div className="image-wrapper">
          <Img
            fluid={data.dan3.childImageSharp.fluid}
            alt="Dan in the 1970s"
            style={{ width: `100%`, }}
          />
          <div className="textbox">
            <p>1977</p>
            <p>Rather at a book-signing event in Houston for “The Camera Never Blinks."</p>
          </div>
        </div>
        <div className="image-wrapper">
          <Img
            fluid={data.dan4.childImageSharp.fluid}
            alt="Dan in the 1980s"
            style={{ width: `100%`, }}
          />
          <div className="textbox">
            <p>1980</p>
            <p>Rather on assignment at the 1980 Democratic National Convention.</p>
            <p style={{fontSize: `1rem`,}}><em>Photo ℅ Briscoe Center for American History</em></p>
          </div>
        </div>
        <div className="image-wrapper">
          <Img
            fluid={data.dan5.childImageSharp.fluid}
            alt="Dan in the 1990s"
            style={{ width: `100%`, }}
          />
          <div className="textbox">
            <p>1996</p>
            <p>Rather in front of Reagan High School, his alma mater, in Houston.</p>
          </div>
        </div>
        <div className="image-wrapper">
          <Img
            fluid={data.dan6.childImageSharp.fluid}
            alt="Dan in the 2010s"
            style={{ width: `100%`, }}
          />
          <div className="textbox">
            <p>2019</p>
            <p>Rather with Heights High School valedictorian Emily Ramirez after she interviewed him in Houston.</p>
          </div>
        </div>
      </div>

      <div id="home-quote">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <p className="quote">The press is a watchdog. Not an attack dog. Not a lap dog. A watchdog.</p>
              <p className="credit">— Dan Rather</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DanRather

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "dan-sitting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dan1: file(relativePath: { eq: "e_rathe_00223.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dan2: file(relativePath: { eq: "dan-1964.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dan3: file(relativePath: { eq: "dan-1977.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dan4: file(relativePath: { eq: "e_rmc_0110.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dan5: file(relativePath: { eq: "dan-high-school.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dan6: file(relativePath: { eq: "dan-2019.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
